import React from 'react';
import './horizontal-separator.styles.scss';


const Separator: React.FC = () => {
  return (
    <hr className='separator'/>
  )
}

export default Separator;