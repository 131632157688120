import React from 'react';
import './header.styles.scss';

const Header: React.FC = () => {
  return (
    <div className="header">
      <div className="logo-container">
        <img src={require('../../assets/images/TAP&GO LOGO 1.png')} alt='logo' className='logo'/>
        <span className='title' style={{fontSize: '12px'}}>Defining a smart transport</span>
      </div>
    </div>
  )
}

export default Header