// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 50px 0;
  min-height: calc(75vh - 120px);
}

.form {
  width: 40%;
  max-width: 450px;
  padding: 10px 10px 40px;
  border-radius: 15px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 767px) {
  .form {
    width: 85%;
  }
}`, "",{"version":3,"sources":["webpack://./src/routes/home/home.route.styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EACA,8BAAA;AACF;;AAEA;EACE,UAAA;EACA,gBAAA;EAEA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EAEA,uCAAA;AADF;;AAIA;EACE;IACE,UAAA;EADF;AACF","sourcesContent":[".form-container {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 50px 0 50px 0;\n  min-height: calc(75vh - 120px);\n}\n\n.form {\n  width: 40%;\n  max-width: 450px;\n  // min-height: 60vh;\n  padding: 10px 10px 40px;\n  border-radius: 15px;\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  //   gap: 20px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n}\n\n@media only screen and (max-width: 767px) {\n  .form {\n    width: 85%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
