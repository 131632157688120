// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  padding: 20px 20px 0 20px;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.logo-container {
  width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  width: 100%;
}

.title-container {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.styles.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,qBAAA;AACJ;;AACA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAEJ;;AAAA;EACI,WAAA;AAGJ;;AADA;EACI,iBAAA;AAIJ","sourcesContent":[".header{\n    padding: 20px 20px 0 20px;\n    display: flex;\n    justify-content: center;\n    align-items: baseline;\n}\n.logo-container{\n    width: 160px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n.logo{\n    width: 100%;\n}\n.title-container{\n    text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
