// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

p {
  padding: 0px 20px 0px 0;
}

.footer-titles {
  color: #1654a7;
  font-size: small;
}

@media only screen and (max-width: 767px) {
  p {
    padding: 0 0 0 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;AACF;;AAEA;EACE;IACE,gBAAA;EACF;AACF","sourcesContent":[".footer {\n  width: 100%;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\np {\n  padding: 0px 20px 0px 0;\n}\n\n.footer-titles {\n  color: #1654a7;\n  font-size: small;\n}\n\n@media only screen and (max-width: 767px) {\n  p {\n    padding: 0 0 0 0;\n    // font-size: small;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
