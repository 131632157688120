import React from "react";
import "./input-box.styles.scss";
import { Fragment } from "react";
import { Field } from "formik";

type Props = {
  type: string;
  placeholder: string;
  name: string;
  children: React.ReactNode;
  values: {};
  handleBlur: any;
  handleChange: any;
  touched: any;
  fieldError: string;
  errors: any;
};

const InputBox: React.FC<Props> = (props: Props) => {
  return (
    <Fragment>
      <div className="input-field">
        <div className="icon i">{props.children}</div>
        <Field
          type={props.type}
          name={props.name}
          className={"input"}
          placeholder={props.placeholder}
          onChange={props.handleChange}
          value={props.values}
          onBlur={props.handleBlur}
        />
      </div>
      <label className="error">
        {props.touched && props.errors}
      </label>
    </Fragment>
  );
};

export default InputBox;
