// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-method-container {
  display: flex;
  align-items: center;
  padding: 0px 0px 20px 20px;
  justify-content: center;
}

.payment-icons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.title {
  color: #1654a7;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 767px) {
  .payment-method-container {
    justify-content: center;
  }
  .payment-method-container p {
    font-size: small;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/payment-method/payment-method.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,0BAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AACF;;AACA;EACE,cAAA;EACA,kCAAA;AAEF;;AAAA;EACE;IACE,uBAAA;EAGF;EADE;IACE,gBAAA;EAGJ;AACF","sourcesContent":[".payment-method-container {\n  display: flex;\n  align-items: center;\n  padding: 0px 0px 20px 20px;\n  justify-content: center;\n}\n\n.payment-icons {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n}\n.title {\n  color: #1654a7;\n  font-family: \"Poppins\", sans-serif;\n}\n@media only screen and (max-width: 767px) {\n  .payment-method-container {\n    justify-content: center;\n\n    p {\n      font-size: small;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
