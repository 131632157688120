import React from "react";
import "./footer.styles.scss";

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <p className="footer-titles">Powered by AC Mobility </p>
      <p className="footer-titles" id="vertical-separator">
        |
      </p>
      <p className="footer-titles">Contact us 6655</p>
    </div>
  );
};

export default Footer;
