import * as React from "react";

export const CardIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 17.6385C0 18.7886 0.93316 19.7218 2.08333 19.7218H22.9167C24.0668 19.7218 25 18.7886 25 17.6385V9.99956H0V17.6385ZM8.33333 14.6871C8.33333 14.4006 8.56771 14.1662 8.85417 14.1662H14.7569C15.0434 14.1662 15.2778 14.4006 15.2778 14.6871V16.4232C15.2778 16.7096 15.0434 16.944 14.7569 16.944H8.85417C8.56771 16.944 8.33333 16.7096 8.33333 16.4232V14.6871ZM2.77778 14.6871C2.77778 14.4006 3.01215 14.1662 3.29861 14.1662H6.42361C6.71007 14.1662 6.94444 14.4006 6.94444 14.6871V16.4232C6.94444 16.7096 6.71007 16.944 6.42361 16.944H3.29861C3.01215 16.944 2.77778 16.7096 2.77778 16.4232V14.6871ZM25 2.36068V4.44401H0V2.36068C0 1.2105 0.93316 0.277344 2.08333 0.277344H22.9167C24.0668 0.277344 25 1.2105 25 2.36068Z"
        fill="black"
      />
    </svg>
  );
};
