import * as React from "react";

export const CurrencyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="18"
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.75 0H1.25C0.559766 0 0 0.559766 0 1.25V16.25C0 16.9402 0.559766 17.5 1.25 17.5H23.75C24.4402 17.5 25 16.9402 25 16.25V1.25C25 0.559766 24.4402 0 23.75 0ZM6.875 11.5578V12.1875C6.875 12.3602 6.73516 12.5 6.5625 12.5H5.9375C5.76484 12.5 5.625 12.3602 5.625 12.1875V11.5512C5.18398 11.5285 4.75508 11.3746 4.39961 11.1078C4.24727 10.9934 4.23945 10.7652 4.37734 10.6336L4.83633 10.1957C4.94453 10.0926 5.10547 10.0879 5.23203 10.1672C5.3832 10.2617 5.55469 10.3125 5.73281 10.3125H6.83086C7.08477 10.3125 7.2918 10.0812 7.2918 9.79727C7.2918 9.56484 7.15078 9.36016 6.94922 9.3L5.19141 8.77266C4.46523 8.55469 3.95781 7.85781 3.95781 7.07773C3.95781 6.11992 4.70195 5.3418 5.62461 5.31719V4.6875C5.62461 4.51484 5.76445 4.375 5.93711 4.375H6.56211C6.73477 4.375 6.87461 4.51484 6.87461 4.6875V5.32383C7.31563 5.34648 7.74453 5.5 8.1 5.76719C8.25234 5.88164 8.26016 6.10977 8.12227 6.24141L7.66328 6.6793C7.55508 6.78242 7.39414 6.78711 7.26758 6.70781C7.11641 6.61289 6.94492 6.5625 6.7668 6.5625H5.66875C5.41484 6.5625 5.20781 6.79375 5.20781 7.07773C5.20781 7.31016 5.34883 7.51484 5.55039 7.575L7.3082 8.10234C8.03437 8.32031 8.5418 9.01719 8.5418 9.79727C8.5418 10.7555 7.79766 11.5332 6.875 11.5578ZM16.25 10.9375C16.25 11.1102 16.1102 11.25 15.9375 11.25H11.5625C11.3898 11.25 11.25 11.1102 11.25 10.9375V10.3125C11.25 10.1398 11.3898 10 11.5625 10H15.9375C16.1102 10 16.25 10.1398 16.25 10.3125V10.9375ZM22.5 10.9375C22.5 11.1102 22.3602 11.25 22.1875 11.25H19.0625C18.8898 11.25 18.75 11.1102 18.75 10.9375V10.3125C18.75 10.1398 18.8898 10 19.0625 10H22.1875C22.3602 10 22.5 10.1398 22.5 10.3125V10.9375ZM22.5 7.1875C22.5 7.36016 22.3602 7.5 22.1875 7.5H11.5625C11.3898 7.5 11.25 7.36016 11.25 7.1875V6.5625C11.25 6.38984 11.3898 6.25 11.5625 6.25H22.1875C22.3602 6.25 22.5 6.38984 22.5 6.5625V7.1875Z"
        fill="black"
      />
    </svg>
  );
};
