import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import api from '../apis';
import {TopupState} from '../types';

const topupCardSlice = createSlice({
  name: 'topup',
  initialState: {
    loading: false,
    message: '',
    error: '',
    success: false,
  } as TopupState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(api.topUpCard.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      api.topUpCard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.message = action?.payload?.message;
      },
    );
    builder.addCase(
      api.topUpCard.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action?.payload?.error;
        state.message =
          action?.payload?.message ||
          action?.payload?.error?.message ||
          action?.payload?.error?.error?.message;
      },
    );
    builder.addCase(api.resetAll, state => {
      state.loading = false;
      state.error = '';
      state.message = '';
      state.success = false;
    });
  },
});

export default topupCardSlice.reducer;
