import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { TopupData, TopupResponse } from "./types";
import axios from "./axios";

class Api {
  resetAll = createAction("resetAll");
  topUpCard = createAsyncThunk(
    "/topup",
    async (data: TopupData, { rejectWithValue }) => {
      try {
        const response = await axios.post("/cards/cardTopup", { ...data });
        return response.data as TopupResponse;
      } catch (error: any) {
        return rejectWithValue({ error: error?.response?.data });
      }
    }
  );
}

const apis = new Api();
export default apis;
