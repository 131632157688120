// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  height: 50px;
  width: 90%;
  border: 0;
  border-radius: 15px;
  margin-top: 20px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  background: linear-gradient(#19459d, #00a5df);
}
.button:hover {
  background: linear-gradient(rgba(25, 69, 157, 0.3), rgba(0, 165, 223, 0.3));
}
.button.disbled {
  background: linear-gradient(rgba(25, 69, 157, 0.3), rgba(0, 165, 223, 0.3));
  cursor: not-allowed;
}

@media only screen and (max-width: 375px) {
  .button {
    height: 35px;
    border-radius: 12px;
    font-size: small;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/button/button.styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,UAAA;EACA,SAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;EACA,eAAA;EACA,6CAAA;AACF;AAAE;EACE,2EAAA;AAEJ;AAAE;EACE,2EAAA;EACA,mBAAA;AAEJ;;AAEA;EACE;IACE,YAAA;IACA,mBAAA;IACA,gBAAA;EACF;AACF","sourcesContent":[".button {\n  height: 50px;\n  width: 90%;\n  border: 0;\n  border-radius: 15px;\n  margin-top: 20px;\n  font-size: 18px;\n  color: #ffff;\n  cursor: pointer;\n  background: linear-gradient(#19459d, #00a5df);\n  &:hover {\n    background: linear-gradient(rgba(25, 69, 157, 0.3), rgba(0, 165, 223, 0.3));\n  }\n  &.disbled {\n    background: linear-gradient(rgba(25, 69, 157, 0.3), rgba(0, 165, 223, 0.3));\n    cursor: not-allowed;\n  }\n}\n\n@media only screen and (max-width: 375px) {\n  .button {\n    height: 35px;\n    border-radius: 12px;\n    font-size: small;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
