// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 90%;
}

input {
  height: 50px;
  width: 100%;
  outline: none;
  padding: 0 50px;
  margin: 15px 0;
  font-size: 18px;
  border-radius: 15px;
  border: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.icon {
  position: absolute;
  top: 52%;
  font-size: 18px;
  pointer-events: none;
  transform: translateY(-50%);
  left: 15px;
}

.error {
  color: red;
}

@media only screen and (max-width: 375px) {
  input {
    height: 35px;
    font-size: small;
    margin: 10px 0;
    border-radius: 12px;
  }
  .icon {
    font-size: small;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/input-box/input-box.styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,UAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;EACA,WAAA;EACA,uCAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,eAAA;EACA,oBAAA;EACA,2BAAA;EACA,UAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE;IACE,YAAA;IACA,gBAAA;IACA,cAAA;IACA,mBAAA;EACF;EACA;IACE,gBAAA;EACF;AACF","sourcesContent":[".input-field {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  width: 90%;\n}\n\ninput {\n  height: 50px;\n  width: 100%;\n  outline: none;\n  padding: 0 50px;\n  margin: 15px 0;\n  font-size: 18px;\n  border-radius: 15px;\n  border: 0px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n}\n\n.icon {\n  position: absolute;\n  top: 52%;\n  font-size: 18px;\n  pointer-events: none;\n  transform: translateY(-50%);\n  left: 15px;\n}\n\n.error {\n  color: red;\n}\n\n@media only screen and (max-width: 375px) {\n  input {\n    height: 35px;\n    font-size: small;\n    margin: 10px 0;\n    border-radius: 12px;\n  }\n  .icon {\n    font-size: small;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
