import React from 'react';
import { Airtel } from '../../assets/icons/airtel';
import { Mtn } from '../../assets/icons/mtn';
import './payment-method.styles.scss';

const PaymentMethods: React.FC = () => {
  return (
    <div className='payment-method-container'>
        <p className="title">We accept</p>
        <div className="payment-icons">
          <Mtn />
          <Airtel />
        </div>
    </div>
  )
}

export default PaymentMethods