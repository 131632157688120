import React, { ReactNode } from "react";
import "./button.styles.scss";

type Props = {
  buttonStatusClass: string;
  children: ReactNode;
  otherProps: any;
  disabled: boolean;
};

const Button: React.FC<Props> = (props: Props) => {
  const { buttonStatusClass, children, otherProps, disabled } = props;
  return (
    <button
      disabled={disabled}
      className={`button ${buttonStatusClass}`}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default Button;
