import * as React from "react";

export const PhoneIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.2866 18.1656L18.8179 15.8218C18.5843 15.7223 18.3246 15.7013 18.078 15.762C17.8315 15.8228 17.6113 15.962 17.4507 16.1587L15.0288 19.1177C11.2279 17.3256 8.16904 14.2668 6.37695 10.4659L9.33594 8.04399C9.53305 7.88368 9.67256 7.66351 9.73335 7.41682C9.79414 7.17013 9.7729 6.91035 9.67285 6.6768L7.3291 1.20805C7.21929 0.9563 7.02508 0.750751 6.77996 0.626852C6.53483 0.502952 6.25416 0.468467 5.98633 0.529342L0.908203 1.70122C0.649985 1.76085 0.419602 1.90624 0.254656 2.11366C0.0897096 2.32109 -5.94829e-05 2.57829 2.95713e-08 2.84331C2.95713e-08 15.3677 10.1514 25.4996 22.6562 25.4996C22.9213 25.4997 23.1787 25.41 23.3862 25.2451C23.5937 25.0801 23.7392 24.8497 23.7988 24.5914L24.9707 19.5132C25.0312 19.2441 24.996 18.9623 24.8711 18.7163C24.7463 18.4704 24.5396 18.2756 24.2866 18.1656Z"
        fill="black"
      />
    </svg>
  );
};
