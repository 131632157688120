import React, { useEffect } from "react";
import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";
import PaymentMethods from "../../components/payment-method/payment-method.component";
import Separator from "../../components/separator/horizontal-separator.component";
import Button from "../../components/button/button.component";
import InputBox from "../../components/input-box/input-box.component";
import apis from "../../store/apis";
import paymentSchema from "../../utils/payment-schema.utils";
import { CardIcon } from "../../assets/icons/card";
import { PhoneIcon } from "../../assets/icons/phone";
import { CurrencyIcon } from "../../assets/icons/dollar";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/types";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import "./home.route.styles.scss";

const Home: React.FC = () => {
  const dispatch = useDispatch();

  const { loading, message, error, success } = useSelector(
    (state: RootState) => state.topupCard
  );

  useEffect(() => {
    let timeout: any;
    if (success) {
      toast.success("Card top up success");
    }
    if (error) {
      toast.error(message);
    }
    timeout = setTimeout(() => {
      toast.clearWaitingQueue();
      dispatch(apis.resetAll());
    }, 4000);

    return () => clearTimeout(timeout);
  }, [error, message, success, dispatch]);

  return (
    <div className="app-container">
      <Header />
      <Separator />
      <Formik
        initialValues={{ card: "", phone: "", amount: "" }}
        validationSchema={paymentSchema}
        onSubmit={(values, { resetForm }) => {
          dispatch(
            apis.topUpCard({
              cardNumber: values.card.toLocaleUpperCase(),
              amount: values.amount,
              phoneNum: `25${values.phone}`,
            }) as unknown as AnyAction
          );
          resetForm();
        }}
      >
        {({ handleChange, values, errors, handleBlur, touched }) => {
          return (
            <div className="form-container">
              <Form className="form">
                <p className="title">Card TopUp</p>
                <InputBox
                  type="text"
                  placeholder="Card Number"
                  name="card"
                  values={values.card}
                  errors={errors.card}
                  touched={touched.card}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  fieldError={"Card"}
                >
                  <CardIcon width={20} />
                </InputBox>
                <InputBox
                  type="text"
                  placeholder="Phone Number"
                  name="phone"
                  values={values.phone}
                  errors={errors.phone}
                  touched={touched.phone}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  fieldError={"Phone"}
                >
                  <PhoneIcon width={20} />
                </InputBox>
                <InputBox
                  type="text"
                  placeholder="Amount"
                  name="amount"
                  values={values.amount}
                  errors={errors.amount}
                  touched={touched.amount}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  fieldError={"Amount"}
                >
                  <CurrencyIcon width={20} />
                </InputBox>
                <Button
                  disabled={loading}
                  buttonStatusClass={loading ? "disbled" : ""}
                  otherProps={{ type: "submit" }}
                >
                  {loading ? "Waiting for payment..." : "Top up"}
                </Button>
              </Form>
            </div>
          );
        }}
      </Formik>
      <PaymentMethods />
      <Separator />
      <Footer />
    </div>
  );
};

export default Home;
