import * as Yup from 'yup';

export default Yup.object().shape({
    phone: Yup.string()
        .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
        .required('Phone number required'),
    amount: Yup.number().required('Amount required').min(100, 'Amount should not below 100'),
    card: Yup.string()
        .matches(/^[A-Za-z\d]{8}$/, 'Card must have exactly 8 characters')
        .required('Card number required'),
});
