import * as React from "react";

export const Airtel = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="119"
      height="40"
      viewBox="0 0 119 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_1248_44614)">
        <path
          d="M46.6202 16.2151C46.8067 15.8351 46.8741 15.4151 47.0883 15.0391C47.4323 14.4318 47.9293 13.927 48.5291 13.5758C49.1289 13.2245 49.81 13.0394 50.5036 13.0391C51.3542 12.9831 52.2075 12.9831 53.0581 13.0391C53.3279 13.0591 53.4231 12.9911 53.3993 12.6871C53.316 11.4871 52.713 10.8791 51.4953 10.8391C50.5825 10.8625 49.6877 11.0994 48.8812 11.5311C48.3695 11.7591 48.3695 11.7631 48.1593 11.2391C48.0165 10.8791 47.8935 10.5111 47.719 10.1671C47.5762 9.88314 47.6754 9.76714 47.9173 9.64714C49.3588 8.84816 51.003 8.49872 52.6416 8.64314C54.383 8.83914 56.0847 10.3591 56.0093 12.3271C55.9419 14.0591 56.1402 15.7951 56.0569 17.5271C56.0296 17.6816 56.0176 17.8383 56.0212 17.9951C56.1601 18.8231 55.6722 19.3031 55.097 19.7351C54.3829 20.2759 53.5681 20.6658 52.7011 20.8816C51.8342 21.0974 50.933 21.1347 50.0514 20.9911C48.3933 20.7431 47.3342 19.6511 46.771 18.0711C46.7234 17.9391 46.771 17.7751 46.6202 17.6711V16.2151ZM53.2922 16.6151C53.2922 16.2151 53.2922 15.8151 53.2922 15.4151C53.2922 15.2551 53.2565 15.1831 53.0819 15.1831C52.4592 15.1831 51.8285 15.1431 51.2097 15.1831C49.9919 15.2951 49.3493 16.0511 49.4961 17.1191C49.7063 18.6391 51.3882 19.3791 52.7844 18.5751C52.9475 18.5028 53.0843 18.3812 53.1759 18.2272C53.2676 18.0732 53.3096 17.8943 53.2961 17.7151C53.3277 17.3531 53.3264 16.9889 53.2922 16.6271V16.6151Z"
          fill="#E7272F"
        />
        <path
          d="M90.9437 21.0399C90.2052 21.0688 89.466 21.007 88.7422 20.8559C88.0745 20.7106 87.4781 20.3343 87.0565 19.7923C86.6349 19.2502 86.4146 18.5766 86.4336 17.8879C86.4336 13.9879 86.4336 10.0879 86.4336 6.18795C86.4336 6.00795 86.4336 5.85995 86.6716 5.81995C87.3698 5.71195 88.0719 5.58795 88.766 5.45995C89.012 5.41595 89.1191 5.45995 89.123 5.74795C89.1442 7.99861 89.1442 10.2493 89.123 12.4999C89.123 14.3199 89.123 16.1439 89.123 17.9639C89.123 18.5919 89.484 18.9239 90.2615 19.0599C90.4836 19.0959 90.7097 19.1159 90.9358 19.1439L90.9437 21.0399Z"
          fill="#E7272F"
        />
        <path
          d="M38.4766 4.25586H39.3771C41.0946 4.37986 42.5504 4.99186 43.5024 6.51586C44.0059 7.37 44.2306 8.36199 44.145 9.35186C44.0379 11.3999 43.1216 13.1039 41.8999 14.6679C40.7329 16.0757 39.3543 17.2905 37.8142 18.2679C36.9097 18.9371 35.9092 19.4632 34.8471 19.8279C34.3774 19.9921 33.8795 20.0588 33.3834 20.0239C33.1335 19.9884 32.8934 19.902 32.6777 19.7698C32.462 19.6376 32.2753 19.4624 32.1291 19.255C31.9828 19.0476 31.88 18.8123 31.827 18.5635C31.7739 18.3147 31.7717 18.0576 31.8206 17.8079C32.027 16.8067 32.4939 15.879 33.1732 15.1199C33.4163 14.831 33.7209 14.6012 34.0643 14.4474C34.4077 14.2937 34.781 14.22 35.1565 14.2319C35.4053 14.2413 35.6479 14.3128 35.8626 14.4399C35.9694 14.4901 36.0633 14.5644 36.137 14.657C36.2108 14.7496 36.2626 14.858 36.2882 14.9739C36.3139 15.0899 36.3129 15.2102 36.2851 15.3256C36.2574 15.4411 36.2038 15.5486 36.1284 15.6399C35.7899 16.1104 35.4044 16.5447 34.978 16.9359C34.5063 17.3614 34.088 17.8437 33.7325 18.3719C33.6535 18.4977 33.5883 18.6318 33.5381 18.7719C33.3676 19.2679 33.6095 19.5719 34.1173 19.4679C34.3459 19.4237 34.5693 19.3553 34.7837 19.2639C35.7009 18.8624 36.5623 18.342 37.3461 17.7159C38.5681 16.8393 39.656 15.7868 40.575 14.5919C41.2753 13.6829 41.7304 12.6067 41.8959 11.4679C41.9646 10.9365 41.8806 10.3965 41.6539 9.91186C41.5072 9.56029 41.2406 9.27317 40.9024 9.10228C40.5642 8.9314 40.1766 8.88798 39.8094 8.97986C39.0066 9.13277 38.2412 9.44258 37.5564 9.89186C35.8232 10.8994 34.16 12.0247 32.5782 13.2599C31.9674 13.7476 31.2557 14.0907 30.4957 14.2639C29.3057 14.5239 27.719 13.3079 28.1157 11.7119C28.3827 10.7533 28.8707 9.87203 29.5397 9.13986C30.7662 7.72605 32.2588 6.57175 33.9308 5.74386C35.026 5.12657 36.2046 4.6738 37.4294 4.39986C37.7727 4.31582 38.1236 4.26757 38.4766 4.25586Z"
          fill="#E7262E"
        />
        <path
          d="M81.7133 15.1596C80.7296 15.1596 79.73 15.1596 78.7661 15.1596C78.4131 15.1596 78.3258 15.2636 78.3694 15.5996C78.4189 15.9971 78.4985 16.3903 78.6074 16.7756C79.1627 18.5476 80.4281 19.2516 82.2091 18.7756C82.8107 18.6051 83.3972 18.3843 83.9624 18.1156C84.1687 18.0236 84.2559 18.0556 84.3591 18.2636C84.5376 18.6636 84.7557 19.0956 84.9342 19.5076C84.9977 19.6436 85.0652 19.7596 84.8827 19.8636C83.1612 20.8436 81.3523 21.3916 79.369 20.8916C77.5086 20.4236 76.4932 19.0476 75.9497 17.3156C75.5614 16.1604 75.4499 14.929 75.6244 13.722C75.7988 12.5151 76.2542 11.3669 76.9533 10.3716C77.8656 9.01957 79.25 8.59157 80.797 8.70757C83.3 8.88757 84.4384 10.3076 84.8827 12.8316C84.9864 13.4829 85.0487 14.1402 85.0691 14.7996C85.0691 15.0876 84.9937 15.1676 84.7121 15.1636C83.6966 15.1516 82.701 15.1596 81.7133 15.1596ZM80.3885 13.0836C80.924 13.0836 81.4634 13.0836 82.0029 13.0836C82.1893 13.0836 82.2528 13.0436 82.1972 12.8356C82.1255 12.4859 82.0273 12.1422 81.9037 11.8076C81.8022 11.5127 81.612 11.2571 81.3595 11.0764C81.1071 10.8957 80.8051 10.7989 80.4955 10.7996C80.1807 10.7747 79.8657 10.8452 79.5908 11.002C79.3159 11.1588 79.0937 11.3947 78.9525 11.6796C78.802 12.0016 78.6838 12.3379 78.5995 12.6836C78.5003 12.9876 78.5559 13.0836 78.8891 13.0836C79.3849 13.0676 79.8807 13.0836 80.3805 13.0836H80.3885Z"
          fill="#E7272F"
        />
        <path
          d="M70.21 12.0843C70.21 10.1443 70.21 8.20827 70.21 6.27227C70.21 5.93627 70.3171 5.83227 70.6304 5.79627C71.2929 5.72027 71.9513 5.60827 72.6138 5.48827C72.8954 5.43627 72.9866 5.48827 72.9866 5.80027C72.9866 6.67627 73.0144 7.55227 72.9866 8.42427C72.9866 8.72827 73.0779 8.82427 73.3833 8.82427C73.8196 8.82427 74.3988 8.62427 74.6487 8.91627C74.8986 9.20827 74.7082 9.71627 74.7121 10.1603C74.7121 10.9603 74.7121 10.9603 73.9188 10.9603C72.9668 10.9603 72.9668 10.9603 72.9668 11.9243C72.9668 13.8163 72.9668 15.7083 72.9668 17.6003C72.9668 18.6763 73.2683 18.9683 74.3353 19.0603C74.732 19.0963 74.8351 19.2483 74.8034 19.6203C74.7716 19.9923 74.8034 20.3643 74.8034 20.7363C74.8034 20.9163 74.7478 20.9923 74.5614 20.9963C73.7376 21.0486 72.9113 20.9442 72.1259 20.6883C71.5446 20.4756 71.0456 20.0818 70.7012 19.564C70.3567 19.0462 70.1847 18.4313 70.21 17.8083C70.21 15.9003 70.21 14.0003 70.21 12.0843Z"
          fill="#E7272F"
        />
        <path
          d="M62.3361 16C62.3361 14.564 62.3361 13.132 62.3361 11.696C62.3232 11.4522 62.3659 11.2086 62.4608 10.984C62.5556 10.7593 62.7002 10.5596 62.8835 10.4C63.7156 9.64399 64.7089 9.091 65.7867 8.78374C66.8644 8.47649 67.9978 8.42321 69.0993 8.628C69.369 8.668 69.4047 8.76 69.3016 8.984C69.0279 9.568 68.7502 10.148 68.5082 10.74C68.478 10.8411 68.4092 10.926 68.3171 10.9762C68.2249 11.0264 68.1168 11.0379 68.0164 11.008C67.1346 10.9194 66.245 11.057 65.4301 11.408C65.3107 11.4432 65.2076 11.52 65.1393 11.6248C65.071 11.7296 65.0419 11.8555 65.0572 11.98C65.0572 14.78 65.0572 17.604 65.0572 20.416C65.0572 20.816 64.9462 20.892 64.5773 20.868C63.9656 20.832 63.3524 20.832 62.7407 20.868C62.3877 20.892 62.3202 20.768 62.344 20.444C62.344 18.964 62.344 17.488 62.344 16.008L62.3361 16Z"
          fill="#E7272F"
        />
        <path
          d="M60.5313 14.8799C60.5313 16.7439 60.5313 18.6079 60.5313 20.4799C60.5313 20.7399 60.4956 20.8799 60.1902 20.8799C59.5119 20.8599 58.8257 20.8799 58.1474 20.8799C57.83 20.8799 57.8499 20.7119 57.8499 20.4999V15.9279C57.8499 13.8599 57.8499 11.7959 57.8499 9.73193C57.8499 9.41193 57.8895 9.24393 58.2465 9.21193C58.8685 9.14651 59.4868 9.04905 60.099 8.91993C60.4322 8.85593 60.5155 8.96393 60.4956 9.28793C60.4956 11.1519 60.4956 13.0159 60.4956 14.8879L60.5313 14.8799Z"
          fill="#E7272F"
        />
        <path
          d="M74.0577 35.7476C73.7285 35.5476 73.4072 35.3476 73.3794 34.8996C73.3794 34.6796 73.4191 34.5836 73.6452 34.5876C73.8282 34.5876 74.0111 34.5756 74.1926 34.5516C74.3306 34.5378 74.4632 34.4899 74.5786 34.4122C74.6939 34.3344 74.7885 34.2292 74.854 34.1059C74.9195 33.9825 74.9538 33.8448 74.954 33.7049C74.9542 33.565 74.9202 33.4272 74.855 33.3036C74.1673 31.998 73.6347 30.6151 73.2683 29.1836C73.1692 28.8036 73.2168 28.6316 73.665 28.6596C74.3512 28.7036 74.5496 28.8276 74.7043 29.5076C74.9072 30.4539 75.2312 31.3696 75.6682 32.2316C75.887 31.8587 76.0476 31.4539 76.1442 31.0316C76.3791 30.4296 76.5338 29.7987 76.6043 29.1556C76.64 28.6356 76.9177 28.6276 77.2945 28.6516C77.6713 28.6756 77.9173 28.8716 77.9054 29.2876C77.9053 29.4227 77.888 29.5571 77.8538 29.6876C77.3945 31.3625 76.7575 32.9825 75.9538 34.5196C75.8235 34.8056 75.6345 35.0605 75.3992 35.2674C75.164 35.4742 74.8879 35.6284 74.5892 35.7196L74.0577 35.7476Z"
          fill="#E7272F"
        />
        <path
          d="M55.3469 32.1609C55.3469 32.6849 55.3469 33.2089 55.3469 33.7329C55.3469 33.9849 55.2675 34.0489 55.0295 34.0289C54.2362 33.9689 53.9942 33.7169 53.9903 32.8929C53.9903 32.1849 53.9903 31.4729 53.9704 30.7689C53.9911 30.5588 53.9376 30.3482 53.8193 30.174C53.701 29.9998 53.5256 29.8734 53.3239 29.8169C53.1927 29.7673 53.0511 29.7523 52.9126 29.7731C52.774 29.794 52.643 29.8501 52.5319 29.9361C52.4207 30.0221 52.3332 30.1353 52.2774 30.2649C52.2217 30.3945 52.1996 30.5363 52.2132 30.6769C52.1854 31.6489 52.1854 32.6209 52.2132 33.5889C52.2132 33.9649 52.1299 34.0809 51.7491 34.0129C51.1224 33.9049 50.8645 33.6489 50.8566 33.0049C50.8487 32.3609 50.8566 31.6169 50.8566 30.9249C50.8566 30.2329 50.571 29.8689 50.0633 29.7969C49.9502 29.7691 49.8325 29.7668 49.7184 29.79C49.6044 29.8133 49.4968 29.8615 49.4033 29.9314C49.3098 30.0012 49.2327 30.091 49.1774 30.1942C49.1221 30.2975 49.0901 30.4118 49.0835 30.5289C49.0055 31.2019 48.983 31.8802 49.0161 32.5569C49.0161 32.8489 49.0161 33.1409 49.0161 33.4369C49.0161 34.0889 48.8376 34.2049 48.2227 33.9249C48.0789 33.8663 47.9554 33.7665 47.8672 33.6378C47.7791 33.5091 47.7303 33.3572 47.7269 33.2009C47.6216 32.2214 47.6376 31.2325 47.7745 30.2569C47.8575 29.876 48.0387 29.5239 48.2996 29.236C48.5605 28.9481 48.892 28.7346 49.2608 28.617C49.6296 28.4994 50.0225 28.4818 50.4002 28.5661C50.7778 28.6503 51.1268 28.8334 51.4119 29.0969C51.6023 29.2689 51.6817 29.2769 51.8681 29.0969C52.1581 28.8065 52.5314 28.6155 52.935 28.5509C53.3387 28.4863 53.7522 28.5514 54.1172 28.7369C54.4707 28.872 54.7757 29.1115 54.9922 29.424C55.2087 29.7366 55.3268 30.1077 55.331 30.4889C55.3667 31.0409 55.331 31.5969 55.331 32.1529L55.3469 32.1609Z"
          fill="#E7272F"
        />
        <path
          d="M58.9645 28.5567C60.4044 28.5567 61.4794 29.7567 61.4635 31.2887C61.4635 32.9847 60.3529 34.1807 58.8178 34.1447C57.0605 34.1047 56.287 32.6327 56.3386 31.3127C56.406 29.6607 57.4056 28.5527 58.9645 28.5567ZM60.0435 31.3567C60.055 31.0453 59.9981 30.7352 59.8769 30.4487C59.7927 30.2403 59.6442 30.0648 59.4535 29.9481C59.2628 29.8314 59.0399 29.7797 58.8178 29.8007C58.6103 29.7906 58.4054 29.8499 58.2347 29.9692C58.064 30.0885 57.9372 30.2612 57.8737 30.4607C57.7553 30.7611 57.7005 31.0832 57.7128 31.4063C57.7251 31.7293 57.8042 32.0462 57.9451 32.3367C58.0389 32.5325 58.1922 32.6931 58.3826 32.7952C58.573 32.8973 58.7907 32.9357 59.0042 32.9047C59.2016 32.8982 59.3924 32.8317 59.5517 32.7139C59.711 32.5961 59.8312 32.4326 59.8967 32.2447C60.0114 31.9622 60.0616 31.6573 60.0435 31.3527V31.3567Z"
          fill="#E7272F"
        />
        <path
          d="M72.5463 32.4719C72.6415 33.4959 72.2885 33.9479 71.3563 34.0719C69.7697 34.2959 68.54 33.5919 68.183 32.2039C67.7586 30.6039 68.5519 28.4959 70.7693 28.5519C71.303 28.5529 71.8145 28.7675 72.1916 29.1484C72.5686 29.5294 72.7804 30.0456 72.7804 30.5839C72.8126 30.856 72.7394 31.1302 72.5761 31.3493C72.4128 31.5684 72.1719 31.7154 71.9037 31.7599C71.2219 31.9294 70.5172 31.9862 69.8173 31.9279C69.4642 31.9279 69.4603 32.0479 69.6031 32.2879C69.7328 32.4992 69.9146 32.6729 70.1309 32.7921C70.3471 32.9113 70.5903 32.9719 70.8367 32.9679C71.4462 33.0062 72.0502 32.8309 72.5463 32.4719ZM70.2338 30.9479C70.4916 30.9239 70.7494 30.9119 71.0033 30.8719C71.2572 30.8319 71.4436 30.7959 71.4872 30.5319C71.5136 30.4023 71.4999 30.2677 71.4482 30.1461C71.3964 30.0245 71.309 29.9219 71.1977 29.8519C70.9494 29.6971 70.652 29.6436 70.366 29.7023C70.0801 29.761 69.827 29.9275 69.6586 30.1679C69.5317 30.3759 69.377 30.6279 69.496 30.8519C69.615 31.0759 69.8609 30.8999 70.0513 30.9239C70.1115 30.9359 70.1725 30.944 70.2338 30.9479Z"
          fill="#E7272F"
        />
        <path
          d="M67.0882 32.1882C67.0882 32.6962 67.0882 33.2082 67.0882 33.7122C67.0882 33.9922 66.9969 34.0522 66.7431 34.0282C66.0172 33.9602 65.7355 33.6642 65.7316 32.9362C65.7276 32.2082 65.7316 31.4882 65.7117 30.7682C65.7293 30.5413 65.6609 30.316 65.5203 30.1379C65.3797 29.9597 65.1774 29.8419 64.9541 29.8082C64.2401 29.6802 63.7879 30.0842 63.7641 30.8922C63.7641 31.8162 63.7641 32.7402 63.7641 33.6642C63.7641 33.9882 63.653 34.0642 63.3674 34.0282C62.6812 33.9522 62.4035 33.6522 62.4035 32.9402C62.4035 32.2282 62.4035 31.5842 62.4035 30.9082C62.387 30.595 62.4359 30.2817 62.5473 29.9888C62.6586 29.6958 62.8299 29.4298 63.0498 29.208C63.2698 28.9861 63.5336 28.8135 63.8241 28.7012C64.1146 28.5889 64.4253 28.5395 64.7359 28.5562C65.0424 28.5344 65.3499 28.5783 65.6383 28.685C65.9266 28.7918 66.1893 28.959 66.4089 29.1755C66.6286 29.3921 66.8002 29.6531 66.9125 29.9415C67.0248 30.2298 67.0752 30.5388 67.0604 30.8482C67.0604 31.2962 67.0604 31.7442 67.0604 32.1882H67.0882Z"
          fill="#E7272F"
        />
        <path
          d="M59.0756 7.7758C58.8515 7.78111 58.6286 7.74151 58.4198 7.65931C58.211 7.5771 58.0205 7.45392 57.8592 7.29691C57.698 7.1399 57.5692 6.95218 57.4805 6.74462C57.3917 6.53707 57.3447 6.31381 57.3421 6.0878C57.3279 5.84665 57.3622 5.60509 57.4431 5.3777C57.524 5.1503 57.6497 4.9418 57.8127 4.76477C57.9757 4.58773 58.1726 4.44585 58.3915 4.34767C58.6104 4.24949 58.8467 4.19707 59.0863 4.19355C59.3258 4.19003 59.5636 4.2355 59.7852 4.32721C60.0068 4.41892 60.2077 4.55497 60.3758 4.72714C60.5438 4.89931 60.6755 5.10404 60.7629 5.32896C60.8504 5.55388 60.8917 5.79433 60.8844 6.0358C60.8905 6.27322 60.8473 6.5093 60.7575 6.72885C60.6676 6.94839 60.5332 7.14651 60.3628 7.31044C60.1923 7.47438 59.9898 7.60048 59.7681 7.68063C59.5464 7.76079 59.3105 7.7932 59.0756 7.7758Z"
          fill="#E7272F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1248_44614">
          <rect width="119" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
